:root {
    --primary-color: #302ae6;
    --secondary-color: #536390;
    --font-color: #424242;
    --bg-color: #fff;
    --heading-color: green;
    --header-text-color: white;
    --primary-text-color: black;
}

[data-theme="dark"] {
    --primary-color: #9a97f3;
    --secondary-color: #818cab;
    --font-color: #e1e1ff;
    --bg-color: #161625;
    --heading-color: black;
}

$header-bgcolor: var(--heading-color);
$primary-color: var(--primary-color);
$primary-text-color: var(--font-color);
$header-text-color: var(--header-text-color);
$background-color: var(--bg-color)
