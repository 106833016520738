.our-animals-container {
  // min-height: 70vh;
  margin-bottom: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  .items-count {
    align-items: center;
    text-align: center;
    & > div {
      // background-color: gray;
      // color: white;
      padding: 10%;
    }
  }
  .animals-lists {
    .animals-list-item {
      margin-top: 1.5rem;
      // position: relative;
      transition: transform 500ms;
      &:hover {
        transform: translateY(-1rem);
      }
      > div {
        display: flex;
        flex-direction: column;
        height: 80px;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        background-color: #fdd340;
        text-align: center;

        div {
          pointer-events: none;
        }

        &.index-1 {
          background-color: rgb(235, 235, 235);
        }
        &.index-2 {
          background-color: black;
          color: white;
          fill: white;
        }
      }
      .icon {
        font-size: 3rem;
      }
    }
  }
}
