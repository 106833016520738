.topic-container {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 1s;
  overflow: hidden;
  &:hover {
    transform: scale(1.05);
  }
  .background-bgcolor{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(70, 70, 70, 0.329);
    padding: 1rem;
    display: flex;
    align-items: center;
    padding-left: 25%;
    > div {
      max-width: 90%;
    }
  }
}