.imge-info-form-container {
  align-items: center;
  border: 1px solid gray;
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
  .preview-image {
    width: 100%;
    object-fit: contain;
    border: 1px solid gray;
    // max-width: 320px;
    // max-height: 320px;
    // min-width: 320px;
    // min-height: 320px;
  }
  .form-fields {
    border-left: 1px solid gray;
    padding: 2rem;
    .text-fields {
      padding: 0rem;
    }
    .pt-0 {
      padding-top: 0;
    }
  }
}