@import "/src/styles/Variables.scss";

.footer-details {
  padding: 2rem;
  .social,
  .phonenumber,
  .location,
  .email {
    padding-bottom: 1rem;
    text-decoration: none;
    a {
      text-decoration: none;
      color: unset;
    }
    ul {
      padding: 0;
      margin: 0;
      padding-left: 0;
    }
    li {
      text-align: left;
      list-style: none;
      padding-bottom: 1rem;
    }
  }
  .phonenumber,
  .location,
  .email {
    a {
      display: flex;
      > div:first-child {
        margin-right: 1rem;
      }
    }
  }
  .social {
    li a {
      display: flex;
      align-self: center;
      align-items: center;
      > div:first-child {
        margin-right: 1rem;
      }
    }
  }
}
