.image-edit-card {
  position: relative;
  width: 300px;
  height: 250px;
  img {
    width: 300px;
    height: 250px;
    object-fit: cover;
  }
  .card-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: calc(100% - 20px);
    height: 60px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 15px;
    padding: 10px;
    .image-details {
      flex: 3;
      .name {
        font-size: 16px;
        color: #fff;
        margin: 0;
      }
      .botanical-name {
        font-size: 14px;
        color: #fff;
        margin: 0;
      }
    }
    .image-action {
      flex: 1;
      display: flex;
      flex-direction: row nowrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      span {
        cursor: pointer;
      }
    }
  }
}
