.ticker-pricing-page {
  padding: 1rem;
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  > div {
    max-width: 1366px;
    display: block;
    width: 100%;
    > h1 {
      margin-bottom: 3rem;
    }
    > div {
      margin-bottom: 3rem;
    }
  }
  .price-item,
  .timing-item {
    padding: 1rem;
    // height: 220px;
    .icon {
      svg {
        font-size: 4rem;
        border-radius: 50%;
        padding: 2rem;
        background-color: rgb(235, 235, 235);
      }
      .icon-svg {
        height: 5rem;
        width: 5rem;
      }
      @media only screen and (max-width: "560px") {
        svg {
          padding: 1rem;
        }
        .icon-svg {
          height: 3rem;
          width: 3rem;
        }
      }
    }
    .cost,
    .time {
      font-weight: bold;
      font-size: 1.5rem;
      color: #14b72a;
    }
  }
}
