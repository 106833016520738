.image-with-para-container {
  padding: 1rem;
  margin: 1rem;
  background-color: rgba(228, 228, 228, 0.397);
  // color: white;
  max-width: 1360px;
  .image-grid {
    overflow: hidden;
    position: relative;
  }
  .image-src {
    height: 100%;
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    &+ .image-text {
      position: absolute;
      bottom: 0;
    }
  }
  .paragraph-grid {
    padding-top: 0px !important;
  }
}
