.home-page-wrpper {
  .home-image-wrapper {
    background-image: url(../../media/images/animals/HomeBackgroud.jpg);
    height: 100vh;
    background-size: cover;
    background-position-x: center;
    .home-page-text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      font-size: 2rem;
      background-color: rgba(0, 0, 0, 0.281);
      color: white;
      text-align: center;
      @media only screen and (max-width: 600px) {
        font-size: .75rem;
        margin: auto;
      }
      @media only screen and (min-width: 600px) and (max-width: 900px) {
        font-size: 1rem;
      }
      h2 {
        font-size: 3em;
        margin: 0;
      }
      h4 {
        margin: 0;
        font-size: 1em;
      }
      p {
        max-width: 1440px;
        margin: 0 3rem;
        font-size: 1em;
      }
    }
  }
  .image-wrapper {
    position: relative;
    .slide-description {
      position: absolute;
      transform: translateY(10vh);
      &.right {
        right: 1rem;
      }
    }
    .slide-image {
      height: calc(100vh - 4rem);
      width: 100%;
      max-width: 100vw;
      max-height: auto;
      object-fit: cover;
      overflow: hidden;
    }
  }
  .image-tiger {
    .image-text-container {
      padding: 1rem;
      margin: 0;
      color: white;
      font-size: 1rem;
      background-color: #313131;
      opacity: .8;
      * {
        line-height: .75em;
      }
      p {
        line-height: 1.5em;
        margin-bottom: 2em;
      }
    }
    .right-text-container {
      color: white;
      font-size: 1rem;
      line-height: 1rem;
      margin: 2rem;
      * {
        line-height: .75em;
      }
      p {
        line-height: 1.5em;
        margin-bottom: 2em;
      }
    }
  }
}
