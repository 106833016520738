.our-services-container {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  width: 100%;
  .member-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    height: 400px;
    background-image: url(../../media/images/animals/Butterfly.jpg);
    background-size: cover;
    background-position: center;
    &> div {
      padding: 3rem;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.295);
      color: white;
      h1:nth-child(2) {
        letter-spacing: .25rem;
        font-weight: 300;
        font-style: italic;
      }
    }
  }
}