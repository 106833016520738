.about-us {
  margin-top: 5rem;
  padding: 1rem;
  display: flex;
  height: 60vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    align-items: flex-start;
  }
  > div {
    max-width: 1366px;
  }
}