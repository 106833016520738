.landing-page-container {
  height: 50vh;
  &.full-height {
    height: 100vh;
  }
  .page-description {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5rem;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.199);
    color: white;
  }
}
