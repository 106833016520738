@import "/src/styles/Variables.scss";

body {
  background-color: $background-color;
  color: $primary-text-color;
}
.app-body {
  box-sizing: border-box;
}

.theme-switch-wrapper {
  width: 3rem;
  height: 28px;
  margin-right: 1rem;
  display: inline-flex;
}
.topnav {
  .link {
    
    text-decoration: none;
  }
}
#menu-appbar {
  .link {
    text-decoration: none;
  }
}
